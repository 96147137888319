/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages



                if (!screencheck(1023)) {
                    $('.row-equal .panels').equalHeights();
                }


                $(window).on('resize', function() {
                    if (!screencheck(1023)) {
                        $('.row-equal .panels').equalHeights();
                    }else{
                        $('.row-equal .panels').css('height', 'auto');
                    }
                }).resize();

                $('.js-panel-link').on('click',function(){
                    if($(this).attr('data-link')){
                        window.location.href = $(this).attr('data-link');
                    }
                });

                $('.logo').on('click',function(){
                    window.location.href = '/';
                });

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {

                if (!screencheck(1023)) {
                    $home_scrolling();
                }

                $(window).on('resize', function() {
                    if (!screencheck(1023)) {
                        //$home_scrolling();
                    }
                }).resize();



                // JavaScript to be fired on the home page

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS


            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page


            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    /* Screen Check
    --------------------------------------------------------------------------------------------------------------------------------------*/
    var screencheck = function(mediasize) {
        if (typeof window.matchMedia !== "undefined") {
            var screensize = window.matchMedia("(max-width:" + mediasize + "px)");
            if (screensize.matches) {
                return true;
            } else {
                return false;
            }
        } else { // for IE9 and lower browser
            if ($winW() <= mediasize) {
                return true;
            } else {
                return false;
            }
        }
    };

    $home_scrolling = function() {
        var controller = new ScrollMagic.Controller();

        var scene_btn = new ScrollMagic.Scene({
                triggerElement: "#trigger-btn",
                offset: 80
            })
            // trigger animation by adding a css class
            .setClassToggle(".banner", "banner-fixed")
            //.setClassToggle(".panel-home", "pt10")
            //.addIndicators({name: "1 - add a class"}) // add indicators (requires plugin)
            .addTo(controller);

        var scene_floatbtn = new ScrollMagic.Scene({
                triggerElement: "#trigger-btn",
                duration: '70%',
                triggerHook: '10%', // don't trigger until #pinned-trigger1 hits the top of the viewport
                reverse: true // allows the effect to trigger when scrolled in the reverse direction
            })
            .setPin(".title-opening")
            //.addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
            .addTo(controller);

        var scene_floatbtn_2 = new ScrollMagic.Scene({
                triggerElement: "#trigger-btn2",
                duration: '30%',
                triggerHook: '10%', // don't trigger until #pinned-trigger1 hits the top of the viewport
                reverse: true // allows the effect to trigger when scrolled in the reverse direction
            })
            .setClassToggle(".title-opening", "fade")
            //.addIndicators({name: "end"}) // add indicators (requires plugin)
            .addTo(controller);

        $('.js-more-btn').on('click', function(e) {
            e.preventDefault();

            var target = this.hash;
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': ($target.offset().top - 190)
            }, 1100, 'swing', function() {
                window.location.hash = target;
            });
        });
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
